export default function useCinemaStrings() {
  const cinemaStrings = useCinemaStringsState()
  const { locale } = useI18n()

  async function fetchCinemaStrings({
    cinemaId,
    keys,
  }: {
    cinemaId?: string
    keys: string[]
  }) {
    useGqlHeaders({ 'Accept-Language': locale.value })

    const { data } = await useAsyncData(
      getGqlFetchKey('UseCinemaStrings', cinemaId),
      () => GqlFetchCinemaStrings({ cinemaId, keys })
    )

    if (data.value?.cinemaStrings) {
      cinemaStrings.value = {
        ...cinemaStrings.value,
        ...data.value.cinemaStrings.reduce(
          (acc: { [key: string]: string }, { key, value }) => {
            acc[key] = value
            return acc
          },
          {}
        ),
      }
    }
  }

  function ct(
    key: string,
    placeholders?: { [key: string]: string } | null,
    fallback?: string
  ): string {
    let string = (
      cinemaStrings.value as unknown as {
        [key: string]: string
      }
    )[key]

    if (!string) {
      return fallback ?? key
    }

    string = decodeURIComponent(string)

    if (placeholders) {
      for (const key in placeholders) {
        const regex = new RegExp('\\{' + key + '\\}', 'g')
        string = string.replace(regex, placeholders[key])
      }
    }
    return string
  }

  return {
    cinemaStrings,
    fetchCinemaStrings,
    ct,
  }
}
