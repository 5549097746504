<template>
  <div class="relative">
    <ImageWrapper
      :image="imageObject"
      :config="imageConfig"
      :classes="{
        placeholder: {
          'w-full h-full absolute top-0': true,
          'rounded-lg': rounded === ROUNDED_TYPE.LG,
          'rounded-full': rounded === ROUNDED_TYPE.FULL,
        },
        image: {
          'w-full h-auto absolute top-0': true,
          'rounded-lg': rounded === ROUNDED_TYPE.LG,
          'rounded-full': rounded === ROUNDED_TYPE.FULL,
        },
        container: 'aspect-[2/3] relative',
      }"
    >
      <template #default="{ classList }">
        <ImagePlaceholder
          :rounded="rounded"
          :icon="SVG_ICONS.MOVIE_ROLL"
          :class="[...classList, '!border-0']"
        />
      </template>
    </ImageWrapper>

    <div
      v-if="withTrailerButton"
      class="absolute left-0 top-0 aspect-[2/3] w-full"
    >
      <button
        :type="BUTTON_TYPE.BUTTON"
        class="absolute inset-0 left-1/2 top-1/2 z-0 h-10 w-10 -translate-x-1/2 -translate-y-1/2 rounded-full backdrop-blur-lg transition-all hover:scale-105 focus:outline-none"
        :title="t('btn.playTrailer')"
      >
        <InlineSvg :name="SVG_ICONS.PLAY_TRAILER" class="w-10 w-10" />
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
interface Props {
  imageObject: ImageObject
  imageConfig: ImageConfig
  rounded?: RoundedTypes
  withTrailerButton?: boolean
}

withDefaults(defineProps<Props>(), {
  rounded: ROUNDED_TYPE.LG,
  withTrailerButton: false,
})

const { t } = useI18n()

defineOptions({
  name: 'MoviePosterImage',
})
</script>

<i18n>
de:
  btn:
    playTrailer: "Trailer abspielen"
es:
  btn:
    playTrailer: "Reproducir tráiler"
</i18n>
