import defaultTheme from '@theme/defaultTheme.json'

export default function useDesign() {
  const design = useDesignState()
  const cmsConfig = useCmsConfig()
  const img = useImage()

  function extractColorValue(text: string, variableName: string) {
    const regex = new RegExp(`${variableName}:\\s*(\\d+)\\s*(\\d+)\\s*(\\d+);`)
    const match = text.match(regex)
    if (match) {
      return [
        parseInt(match[1], 10),
        parseInt(match[2], 10),
        parseInt(match[3], 10),
      ]
    }
    return null
  }

  const cssVars = computed(() => design.value?.cssVars ?? '')
  const customCss = computed(() => design.value?.customCss ?? '')
  const themeColor = computed(() =>
    design.value?.cssVars
      ? `rgb(${extractColorValue(design.value.cssVars, '--color-header')})`
      : '#000'
  )

  const imgUrl = computed(() => {
    if (!design.value?.background) {
      return ''
    }

    const image = getImage(design.value, 'background.image', '')

    if (!image.src) {
      return ''
    }

    return img(image.src, {
      width: image.width,
      height: image.height,
      quality: design.value.background.quality ?? 75,
      format: 'webp',
      blur: design.value.background.blur ?? 0,
    })
  })

  const backgroundImageStyles = computed(() => {
    if (!design.value?.background) {
      return ''
    }

    return `background-position: ${design.value.background.position}; background-size: ${design.value.background.size}; background-repeat: ${design.value.background.repeat};`
  })

  function applyDesign() {
    useHead({
      meta: [
        {
          key: 'theme-color',
          name: 'theme-color',
          content: themeColor.value,
        },
      ],
      style: [
        {
          key: 'css-vars',
          innerHTML: `
            /* designId: ${design.value?.id} - ${design.value?.description} */
            :root { ${cssVars.value} --body-background-image-url: url(${imgUrl.value});
            }
          `,
        },
        {
          key: 'custom-css',
          innerHTML: `
            /* designId: ${design.value?.id} - ${design.value?.description} */
            ${customCss.value ?? ''}
          `,
        },
      ],
      bodyAttrs: {
        tagDuplicateStrategy: 'replace',
        class: `bg-body ${design.value?.isDarkTheme ? 'dark' : ''} font-${design.value?.fontStack} ${
          design.value?.background
            ? 'lg:bg-[image:var(--body-background-image-url)]'
            : ''
        }`,
        style: backgroundImageStyles.value,
      },
    })
  }

  design.value = cmsConfig.value?.defaultDesign ?? defaultTheme
  applyDesign()

  return {
    design,
    cssVars,
    customCss,
    applyDesign,
  }
}
