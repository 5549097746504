<template>
  <Teleport to="body">
    <div
      data-modal-content
      :class="{
        'fixed inset-0 z-50 flex items-center justify-center': isOpen,
        contents: !isOpen,
      }"
    >
      <BackgroundBlur
        v-if="showOverlay"
        :with-scroll-lock="withScrollLock"
        :visible="isOpen"
        @click.self="close"
      />
      <div :class="cssClasses.outer" class="z-40" @click.self="close">
        <TransitionScale v-bind="transition">
          <div
            v-if="isOpen"
            :class="cssClasses.modal"
            class="w-full overflow-hidden"
          >
            <slot name="header">
              <div :class="cssClasses.header">
                <div v-text="title" />
                <Button
                  v-if="showCloseButton"
                  :theme="BUTTON_THEME.TERTIARY"
                  :title="t('btn.closeThisWindow')"
                  :icon="SVG_ICONS.CLOSE"
                  :class="cssClasses.close"
                  @click="close(), activeElement?.blur()"
                />
              </div>
            </slot>

            <div :class="cssClasses.content">
              <slot name="default" />
            </div>

            <div v-if="slot.footer" :class="cssClasses.footer">
              <slot name="footer" />
            </div>
          </div>
        </TransitionScale>
      </div>
    </div>
  </Teleport>
</template>

<script lang="ts" setup>
import { useActiveElement } from '@vueuse/core'

interface Props {
  open: boolean
  title?: string
  showCloseButton?: boolean
  withScrollLock?: boolean
  showOverlay?: boolean
  closeOnClick?: boolean
  classes?: {
    header?: string
    content?: string
    footer?: string
    outer?: string
    modal?: string
    close?: string
  }
  transition?: {
    from?: TransitionPositions
    to?: TransitionPositions
  }
}

const props = withDefaults(defineProps<Props>(), {
  title: undefined,
  showCloseButton: true,
  showOverlay: true,
  withScrollLock: true,
  closeOnClick: true,
  classes: () => ({}),
  transition: () => ({
    from: TRANSITION_POSITIONS.CENTER,
    to: TRANSITION_POSITIONS.CENTER,
  }),
})

const emit = defineEmits(['close'])
const slot = useSlots()
const activeElement = useActiveElement()
const { t } = useI18n()
const isOpen = ref(false)
const { remove: removeScrollLock } = useScrollLockWithScrollBar()

const cssClasses = computed(() => {
  const { header, footer, content, outer, modal, close } = props.classes

  return {
    outer: outer ?? 'h-[100dvh] flex justify-center items-center sm:p-8',
    header:
      header ?? 'h-16 pl-8 pr-4 text-lg grid grid-cols-[1fr,auto] items-center',
    content: content ?? 'px-8 py-4 overflow-y-auto scrollbar-themed',
    footer:
      footer ?? 'h-16 px-5 sm:px-8 flex items-center justify-between space-x-2',
    modal:
      modal ??
      'sm:rounded-lg grid grid-rows-[auto,minmax(0,1fr),auto] bg-light text-dark dark:bg-neutral-dark-2 dark:text-light drop-shadow-2xl max-h-full h-full sm:h-auto max-w-2xl divide-y divide-neutral-light-1 dark:divide-neutral',
    close: close ?? '',
  }
})

async function close() {
  isOpen.value = false

  await nextTick()
  emit('close')
}

defineExpose({ close })

watch(
  () => props.open,
  async () => {
    await nextTick()
    isOpen.value = props.open
  },
  {
    immediate: true,
  }
)

onBeforeUnmount(() => {
  // Failsafe
  removeScrollLock()
})

defineOptions({
  name: 'ModalContent',
})
</script>

<i18n>
de:
  btn:
    closeThisWindow: "Fenster schließen"
es:
  btn:
    closeThisWindow: "Cerrar ventana"
</i18n>
