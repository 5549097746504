
import { updateAppConfig } from '#app/config'
import { defuFn } from 'defu'

const inlineConfig = {
  "nuxt": {
    "buildId": "b5ca5f3b-83c8-42fc-a6e5-e048dd95d0e4"
  }
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}

import cfg0 from "/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/apps/entradas/app.config.ts"
import cfg1 from "/home/forge/deployments/entradas/live/2024-08-22_18-02-42_5cfb041/layers/base/app.config.ts"

export default /*@__PURE__*/ defuFn(cfg0, cfg1, inlineConfig)
