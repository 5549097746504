import type { OpenGraphTag, TwitterTag, Maybe } from '#gql/default'

function formatTags(
  tags: Maybe<OpenGraphTag[] | TwitterTag[]>
): Record<string, string> {
  if (!tags) {
    return {}
  }

  return tags.reduce((object: Record<string, string>, tag) => {
    const tagName = (tag as OpenGraphTag)?.property ?? (tag as TwitterTag)?.name
    const key = tagName
      .replace('_', ':')
      .split(':')
      .map((word, index) =>
        index !== 0 ? word.charAt(0).toUpperCase() + word.slice(1) : word
      )
      .join('')

    object[key] = tag.content
    return object
  }, {})
}

interface PageMetaInformation {
  title?: Maybe<string>
  description?: Maybe<string>
  jsonLD?: Record<string, unknown>
  twitterTags?: Maybe<TwitterTag[]>
  openGraphTags?: Maybe<OpenGraphTag[]>
  robots?: {
    follow?: boolean
    index?: boolean
    noindex?: boolean
  }
}

export default function usePageMetas(pageMetas: PageMetaInformation): void {
  const route = useRoute()
  const cmsConfig = useCmsConfig()
  const config = useRuntimeConfig()

  const {
    description = '',
    openGraphTags = [],
    twitterTags = [],
    jsonLD,
    robots = {
      follow: true,
      index: true,
    },
  } = pageMetas

  let title = pageMetas.title
  if (
    config.public.PORTAL_NAME &&
    // If the people at entradas.com put "cine.entradas.com" or "entradas" in their title,
    // we don't want to append "Entradas" to the title again.
    !title
      ?.toLowerCase()
      .includes((config.public.PORTAL_NAME as string).toLowerCase())
  ) {
    title = `${pageMetas.title} | ${config.public.PORTAL_NAME}`
  }

  if (jsonLD) {
    useHead({
      script: [
        {
          type: 'application/ld+json',
          children: jsonLD,
        },
      ],
    })
  }

  useSeoMeta({
    title,
    description,
    robots,
    googleSiteVerification:
      route.fullPath === '/' && cmsConfig.value?.googleSiteVerification
        ? cmsConfig.value.googleSiteVerification
        : undefined,
    ogTitle: title,
    ...formatTags(openGraphTags),
    ...formatTags(twitterTags),
  })
}
