<template>
  <component :is="icon" v-if="icon" />
</template>

<script setup lang="ts">
interface Props {
  name:
    | SvgIcons
    | PaymentIcons
    | FlagIcons
    | PortalLogos
    | Logos
    | Illustrations
  type?: InlineSvgTypes
}

const props = withDefaults(defineProps<Props>(), {
  type: INLINE_SVG_TYPES.ICON,
})

const icon = defineAsyncComponent(
  () => import(`@assets/assets/${props.type}/${props.name}.svg?component`)
)
</script>

<script lang="ts">
export default {
  name: 'InlineSvg',
}
</script>
