import type { FetchDesignQuery } from '#gql/default'

export const useDesignState = () =>
  useState<FetchDesignQuery['design']>('design', () => ({
    id: '',
    cssVars: undefined,
    isDarkTheme: false,
    background: undefined,
    fontStack: undefined,
  }))
